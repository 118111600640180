<template>
  <div>
    <Header></Header>
    <div class="content">
      <div class="bread_nav flex-box">
        <div class="bread fs16 col3">
          当前位置：<router-link to="/">首页</router-link>><router-link to="/my_order">我的订单</router-link>><a
            href="javascript:;">订单详情</a>
        </div>
      </div>
      <div class="order_detail m1440">
        <div class="status_box flex-box flex-col-start">
          <div class="status_left">
            <div class="order_num fs18 tc">订单号：{{ detail.tradeNo }}</div>
            <div v-if="detail.status == 'UNPAID'">
              <div class="fs30 fwb normal letter2 tc pb10">待付款</div>
              <div class="already_pay fs20 tc" @click.stop="payMoneyShow">我已付款</div>
              <div class="flex-box flex-center cancel_order fs20" @click.stop="show_cancel(1)">取消订单</div>
            </div>
            <div v-if="detail.status == 'PAID' || detail.status == 'CHECK'">
              <div class="fs30 fwb normal letter2 tc pb10">已付款</div>
              <div class="fs20 letter2 tc" v-if="detail.status == 'CHECK'">信息核对中</div>
            </div>
            <div v-if="detail.status == 'FAILED'">
              <div class="fs30 fwb normal letter2 tc pb10">已付款</div>
              <div class="fs20 edit_info tc" v-if="is_show == 0" @click.stop="change_is_show">修改信息</div>
              <div class="fs20 letter2 tc">信息有误</div>
            </div>
            <div v-if="detail.status == 'COMPLETE'">
              <div class="fs30 fwb normal letter2 tc pb10">已完成</div>
               <!-- mao modify 20250324 -->
               <a href="javscript:;" class="generate fs20" v-if="detail.contractStatus == '0' && (detail.productType == 'REC'||detail.productType == '') && detail.productSub=='IREC'"
                @click="jump_type(2)">生成合同</a>

                <div class="red fs20 letter2 tc" v-if="detail.productSub!='IREC'">若需合同，请联系客服</div>
                <div class="red fs18 letter2 tc pb10" v-if="detail.productSub!='IREC'">Mail:zhang.zirui@climatebridge.com</div> 
                <!-- end -->
              <a href="javscript:;" class="generate fs20" v-if="detail.invoiceStatus == '0'"
                @click="jump_type(1)">申请发票</a>
            </div>
            <div class="fs30 fwb normal letter2 tc pb10" v-if="detail.status == 'CANCEL'">已取消</div>
            <div class="fs20 letter2 tc cursor" v-if="detail.status == 'CANCEL'" @click.stop="show_cancel(2)">删除订单</div>
          </div>
          <div class="status_right flex-grow-1">
            <div class="pt40 fs18">订单状态</div>
            <div class="flex-box status_three tc">
              <div>
                <div class="status status1" :class="detail.status == 'UNPAID' ? 'active' : ''"><span>提交订单</span></div>
                <div class="date_three " :class="detail.status == 'UNPAID' ? 'col0' : 'col9'">
                  <div>{{ createTime_text.day }}</div>
                  <div>{{ createTime_text.time }}</div>
                </div>
              </div>
              <div class="line"></div>
              <div>
                <div class="status status2"
                  :class="detail.status != 'COMPLETE' && detail.status != 'UNPAID' ? 'active' : ''">
                  <span>{{detail.status == 'CANCEL'?'已取消':'已付款'}}</span>
                </div>
                <template v-if="detail.status != 'UNPAID'">
                  <div v-if="detail.status == 'COMPLETE'">
                    <div class="date_three " :class="detail.status == 'COMPLETE' ? 'col9' : 'col0'">
                      <div>
                        {{ purchaseDate_text.day }}</div>
                      <div>
                        {{ purchaseDate_text.time }}</div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="date_three "
                      :class="detail.status != 'COMPLETE' && detail.status != 'UNPAID' ? 'col0' : 'col9'">
                      <div>
                        {{ purchaseDate_text.day }}</div>
                      <div>
                        {{ purchaseDate_text.time }}</div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="date_three col9">
                      <div></div>
                      <div> </div>
                    </div>
                </template>
              </div>
              <div class="line"></div>
              <div>
                <div class="status  status3" :class="detail.status == 'COMPLETE' ? 'active' : ''"><span>已完成</span></div>
                <div class="date_three " :class="detail.status == 'COMPLETE' ? 'col0' : 'col9'">
                  <div v-if="detail.status == 'COMPLETE'">{{ completeDate_text.day }}</div>
                  <div v-if="detail.status == 'COMPLETE'">{{ completeDate_text.time }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box mb30" v-if="detail.status == 'FAILED'">
          <div class="fs20 pb20 fwb">信息错误备注</div>
          <div class="fs16">
            {{ detail.refuseReason ? detail.refuseReason : '' }}
          </div>
        </div>
        <!-- 审核通过和待审核等状态 -->
        <div class="box" v-if="is_show == 0">
          <div class="fs20 fwb pb40">商品信息</div>
          <div class="goods_info flex-box" v-for="(v, index) in detail.product">
            <img :src="oss + v.thumbnail" class="img_about mr20" alt="" />
            <div class="flex-grow-1 flex-box flex-between flex-col-start flex-col info_detail">
              <div class="flex-box flex-between num_price">
                <div class="fs20 fwb">{{ v.productName }}</div>
                <div class="fs18">数量</div>
              </div>
              <div class="flex-box flex-between num_price">
                <div class="fs28 fwb red"><span class="fs16">¥</span>{{ v.price_text ? v.price_text : v.price }}
                  <span class="col9 fs16">{{ v.unit ? v.unit : '' }}</span>
                </div>
                <div class="fs18">x{{ detail.totalCount_text ? detail.totalCount_text : detail.totalCount }}</div>
              </div>
            </div>
          </div>
          <div class="fs20 fwb pt30 pb40 border">填写信息</div>
          <div class="flex-box pb30">
            <div class="fs18 info_title">证书抬头：</div>
            <div class="flex-grow-1 name_box">
              <div class="flex-box flex-between pb20">
                <div class="fs16 col10">
                  <span class="col9">中文名称：</span><span>{{ choose_cert.companyName }}</span>
                </div>
                <div class="target" v-if="choose_cert.defaultFlag == '1'">默认</div>
              </div>
              <div class="fs16 col10 pb20">
                <span class="col9">中文地址：</span><span>{{ choose_cert.address }}</span>
              </div>
              <div class="fs16 col10 pb20">
                <span class="col9">英文名称：</span><span>{{ choose_cert.companyNameEn }}</span>
              </div>
              <div class="fs16 col10 pb20">
                <span class="col9">英文地址：</span><span>{{ choose_cert.addressEn }}</span>
              </div>
              <div class="fs16 col10">
                <span class="col9">国家：</span><span>{{ choose_cert.country }}</span>
              </div>
            </div>
          </div>
         <!-- mao modify DELandADD 20240411 -->
                   <!-- <div class="flex-box pb30 fs18">
                     <div class="info_title" >绿证消费起止日期/绿证汇报期：</div>
                     <div>{{ detail.beginPurchaseDate }} 至 {{ detail.endPurchaseDate }}</div>
                   </div> -->
          <div class="flex-box pb30 fs18" v-if="detail.productType=='ER'">
            <div class="info_title">邮箱：</div>
            <div>{{ detail.mail}}</div>
          </div>
          <div class="flex-box pb30 fs18" v-else>
            <div class="info_title" >绿证消费起止日期/绿证汇报期：</div>
            <div>{{ detail.beginPurchaseDate }} 至 {{ detail.endPurchaseDate }}</div>
          </div>
         <!-- end -->
          <div class="flex-box pb20 fs18">
            <div class="info_title">用途：</div>
            <div class="flex-grow-1">
              {{ detail.useage ? detail.useage : '无' }}
            </div>
          </div>
          <div class="flex-box pb30 fs18">
            <div class="info_title">备注：</div>
            <div class="flex-grow-1">
              {{ detail.remark ? detail.remark : '无' }}
            </div>
          </div>
          <div class="flex-box fs18">
            <div class="info_title">推荐人/公司：</div>
            <div>{{ detail.referee ? detail.referee : '无' }}</div>
          </div>
          <div class="mt30 pt30 border flex-box">
            <div class="fs20 fwb info_title">收款账号</div>
            <div class="flex-grow-1">
              <div class="fs18 flex-box pb20">
                <div class="flex-grow-1 saler flex-box">
                  <span>开户行</span>：{{ bankCard.bankName }}
                </div>
                <div class="flex-grow-1 saler flex-box">
                  <span>账号</span>：{{ bankCard.bankAccount }}
                </div>
              </div>
              <div class="fs18 flex-box">
                <div class="flex-grow-1 saler flex-box"><span>全称</span>：{{ bankCard.cardOwner }}</div>
                <!-- <div class="flex-grow-1 saler flex-box">
                  <span>电话</span>：{{ bankCard.ownerTel }}
                </div> -->
              </div>
            </div>
          </div>
        </div>

        <!-- 审核不通过编辑 -->
        <div class="box" v-if="detail.status == 'FAILED' && is_show == 1">
          <div class="fs20 fwb pb40">商品信息</div>
          <div class="goods_info flex-box" v-for="(v, index) in detail.product">
            <img :src="oss + v.thumbnail" class="img_about mr20" alt="" />
            <div class="flex-grow-1 flex-box flex-between flex-col-start flex-col info_detail">
              <div class="flex-box flex-between num_price">
                <div class="fs20 fwb">{{ v.productName }}</div>
                <div class="fs18">数量</div>
              </div>
              <div class="flex-box flex-between num_price">
                <div class="fs28 fwb red"><span class="fs16">¥</span>{{ v.price_text ? v.price_text : v.price }}
                  <span class="col9 fs16">{{ v.unit ? v.unit : '' }}</span>
                </div>
                <div class="fs18">x{{ detail.totalCount_text ? detail.totalCount_text : detail.totalCount }}</div>
              </div>
            </div>
          </div>
          <div class="mt30 pt30 border flex-box">
            <div class="fs20 fwb info_title">收款账号</div>
            <div class="flex-grow-1">
              <div class="fs18 flex-box pb20">
                <div class="flex-grow-1 saler flex-box">
                  <span>开户行</span>：{{ bankCard.bankName }}
                </div>
                <div class="flex-grow-1 saler flex-box">
                  <span>账号</span>：{{ bankCard.bankAccount }}
                </div>
              </div>
              <div class="fs18 flex-box">
                <div class="flex-grow-1 saler flex-box"><span>全称</span>：{{ bankCard.cardOwner }}</div>
                <!-- <div class="flex-grow-1 saler flex-box">
                  <span>电话</span>：{{ bankCard.ownerTel }}
                </div> -->
              </div>
            </div>
          </div>
          <div class="fs20 fwb pt30 pb40 border">填写信息</div>
          <div class="flex-box pb30">
            <div class="fs18 info_title must">证书抬头</div>
            <div class="flex-grow-1 name_box" v-if="choose_cert.length <= 0">
              <span style="color: lightgray,text-align: center" @click="addCertPopup()">您暂无证书抬头,进行添加</span>
            </div>
            <div class="flex-grow-1 name_box" v-else>
              <div class="flex-box flex-between pb20">
                <div class="fs16 col10">
                  <span class="col9">中文名称：</span><span>{{ choose_cert.companyName }}</span>
                </div>
                <div class="target" v-if="choose_cert.defaultFlag == '1'">默认</div>
              </div>
              <div class="fs16 col10 pb20">
                <span class="col9">中文地址：</span><span>{{ choose_cert.address }}</span>
              </div>
              <div class="fs16 col10 pb20">
                <span class="col9">英文名称：</span><span>{{ choose_cert.companyNameEn }}</span>
              </div>
              <div class="fs16 col10 pb20">
                <span class="col9">英文地址：</span><span>{{ choose_cert.addressEn }}</span>
              </div>
              <div class="flex-box flex-between">
                <div class="fs16 col10">
                  <span class="col9">国家：</span><span>{{ choose_cert.country }}</span>
                </div>
                <div class="fs16 normal use_other" @click="closePop2()">使用其他抬头</div>
              </div>
            </div>
          </div>
          <!-- mao modify DELandADD 20240411-->
                   <!-- <div class="flex-box pb30">
                     <div class="fs18 info_title must">绿证消费起止日期/绿证汇报期</div>
                     <div class="name_right flex-box flex-between">
                       <vue-datepicker-local v-model="post_data.beginPurchaseDate" placeholder="请选择日期" :local="local"
                         format="YYYY-MM-DD" clearable />
                       <div class="line"></div>
                       <vue-datepicker-local v-model="post_data.endPurchaseDate" placeholder="请选择日期" :local="local"
                         format="YYYY-MM-DD" :disabled-date="disabledDate" clearable />
                     </div>
                   </div> -->
          <div class="flex-box pb30" v-if="detail.productType=='ER'">
            <div class="fs18 info_title must">邮箱</div>
            <input type="text" class="input_rec fs18" v-model="post_data.mail" placeholder="请输入邮箱" />
          </div>
          <div class="flex-box pb30" v-else>
            <div class="fs18 info_title must">绿证消费起止日期/绿证汇报期</div>
            <div class="name_right flex-box flex-between">
              <vue-datepicker-local v-model="post_data.beginPurchaseDate" placeholder="请选择日期" :local="local"
                format="YYYY-MM-DD" clearable />
              <div class="line"></div>
              <vue-datepicker-local v-model="post_data.endPurchaseDate" placeholder="请选择日期" :local="local"
                format="YYYY-MM-DD" :disabled-date="disabledDate" clearable />
            </div>
          </div>
                   <!-- end -->
          <div class="flex-box pb20">
            <div class="fs18 info_title must">
              用途<span class="fs16">(须用英文填写)</span>
            </div>
            <textarea name="" v-model="post_data.useage" id="" cols="30" rows="10" class="disclosure fs18"
              placeholder="Disclosure for Scope 2 Reporting"></textarea>
          </div>
          <div class="tr pb30 tips fs16">若无特定内容，使用默认内容即可</div>
          <div class="flex-box pb30">
            <div class="fs18 info_title">备注</div>
            <textarea name="" id="" v-model="post_data.remark" cols="30" rows="10" class="disclosure fs18"
              placeholder="请输入备注"></textarea>
          </div>
          <div class="flex-box">
            <div class="fs18 info_title">推荐人/公司</div>
            <input type="text" class="input_rec fs18" v-model="post_data.referee" placeholder="请输入推荐人/公司" />
          </div>
        </div>

        <!-- <div class="flex-box agree_box" v-if="detail.status == 'FAILED' && is_show == 1" :class="agreement ? 'on' : ''"
          @click="agree()">
          <div class="fs18">我同意<span @click.stop="jump_agree('SERVICE_AGREEMENT')" class="col_nor">《服务协议》</span>,<span
              @click.stop="jump_agree('PRIVACY_POLICY')" class="col_nor">《隐私政策》</span>与<span
              @click.stop="jump_agree('DISCLAIMERS')" class="col_nor">《免责声明》</span></div>
        </div> -->

        <div v-if="detail.status == 'FAILED' && is_show == 1">
          <div class="flex-box agree_box" :class="agreement ? 'on' : ''" @click.stop="agree()">
            <div class="fs18">我同意<span @click.stop="jump_type('SERVICE_AGREEMENT')" class="col_nor">《服务协议》</span>
            </div>
          </div>
          <div class="flex-box agree_box" :class="agreement2 ? 'on' : ''" @click.stop="agree2()">
            <div class="fs18">我同意<span @click.stop="jump_type('PRIVACY_POLICY')" class="col_nor">《隐私政策》</span></div>
          </div>
          <div class="flex-box agree_box" :class="agreement3 ? 'on' : ''" @click.stop="agree3()">
            <div class="fs18">我同意<span @click.stop="jump_type('DISCLAIMERS')" class="col_nor">《免责声明》</span></div>
          </div>
        </div>


        <div class="price_detail mb40">
          <div class="pb20 flex-box flex-end">
            <span class="fs16">商品总额：</span>
            <span class="price tr fs18">¥{{ detail.totalAmount_text ? detail.totalAmount_text : detail.totalAmount }}</span>
          </div>
          <div class="flex-box flex-col-end flex-end">
            <span class="fs16">应付总额：</span>
            <div class="price red tr">
              <span class="fs16">¥</span><span class="fs28">{{
                detail.totalAmount_text ? detail.totalAmount_text : detail.totalAmount
              }}</span>
            </div>
          </div>
        </div>
        <div class="submit fs26" v-if="detail.status == 'FAILED' && is_show == 1" @click="submit">提交订单</div>

        <!-- 取消订单 -->
        <Popup :visible="orderPop" :confirmText="false" :cancleText="false" @close="closePop">
          <div class="cancel_wrap">
            <img src="../assets/static/icon/icon_close.png" class="icon_close" @click="closePop" alt="" />
            <img src="../assets/static/icon/icon_cancel2.png" class="icon_cancel2" alt="" />
            <div class="pt20 fs16 tc pb40">{{ title }}</div>
            <div class="flex-box flex-center btns fs18">
              <div @click="closePop">再想想</div>
              <div @click="save_order">确定</div>
            </div>
          </div>
        </Popup>
      </div>
    </div>

    <!-- 上传支付凭证 -->
    <Popup :visible="voucher" :confirmText="false" :cancleText="false" @close="voucherPop">
      <div class="voucher_pop">
        <img src="../assets/static/icon/icon_close.png" class="icon_close" alt="" @click="voucherPop">
        <div class="title">上传付款凭证</div>
        <div class="ptb10 plr15">
          <div class="fs16 fwb pb10">收款账号</div>
          <div class="flex-box flex-between pb10 fs14 col6">
            <div class="">开户行</div>
            <div class="">{{ bankCard.bankName }}</div>
          </div>
          <div class="flex-box flex-between pb10 fs14 col6">
            <div class="">账号</div>
            <div class="">{{ bankCard.bankAccount }}</div>
          </div>
          <div class="flex-box flex-between pb10 fs14 col6">
            <div class="">全称</div>
            <div class="">{{ bankCard.cardOwner }}</div>
          </div>
          <!-- <div class="flex-box flex-between pb10 fs14 col6">
            <div class="">电话</div>
            <div class="">{{ bankCard.ownerTel }}</div>
          </div> -->
        </div>
        <div class="fs13 col6 ptb10 plr15">上传支付凭证</div>
        <div class="flex-box flex-start">
          <div class="up_box_list" v-for="(item, index) in images" :key="index">
            <img :src="oss + item" class="up_pic" alt="" />
            <img src="../assets/static/icon/icon_close.png" @click.stop="del_img(index)" class="close" alt="">
          </div>
          <div class="up_box">
            <input multiple type="file" @change="upload_img" value=""
              accept="image/png,image/jpeg,image/gif,image/jpg" />
          </div>
        </div>
        <div class="up_voucher" @click="payMoney">确认付款</div>
      </div>
    </Popup>


    <!-- 选择证书抬头 -->
    <Popup :visible="infoPop" :confirmText="false" :cancleText="false" @close="closePop2">
      <div class="name_pop">
        <div class="flex-box name_title flex-between">
          <div class="fs20">选择证书抬头</div>
          <img src="../assets/static/icon/icon_close.png" class="icon_close" @click="closePop2()" alt="" />
        </div>
        <ul class="cirticle_list">
          <li class="flex-box" v-for="(v, k) in certificate" :key="k" @click="choose_certificate(v)">
            <div class="default" v-if="v.defaultFlag == 1">默认</div>
            <div class="agreePop_box" :class="choose_cert.id == v.id ? 'on' : ''"></div>
            <div class="cirticle_box">
              <div class="pb20">
                <span class="fs16 col9">中文公司名称：</span>
                <span class="fs16 col10">{{ v.companyName }}</span>
              </div>
              <div class="pb20">
                <span class="fs16 col9">中文公司详细地址：</span>
                <span class="fs16 col10">{{ v.address }}</span>
              </div>
              <div class="pb20">
                <span class="fs16 col9">英文公司名称：</span>
                <span class="fs16 col10">{{ v.companyNameEn }}</span>
              </div>
              <div class="pb20">
                <span class="fs16 col9">英文公司详细地址：</span>
                <span class="fs16 col10">{{ v.addressEn }}</span>
              </div>
              <div>
                <span class="fs16 col9">国家：</span>
                <span class="fs16 col10">{{ v.country }}</span>
              </div>
            </div>
          </li>
        </ul>
        <Pagination :sumCount="total" :perCount="pageSize" @pageTurn="pageTurn"></Pagination>
        <div class="flex-box flex-between sure_add">
          <div class="sure fs16" @click="closePop2()">确定</div>
          <div class="add fs16" @click="addCertPopup()">新增证书抬头</div>
        </div>
      </div>
    </Popup>
    <!-- 增加|删除 -->
    <Popup :visible="addCertAct" :confirmText="false" :cancleText="false" @close="addCertPopup">
      <div class="name_pop">
        <div class="flex-box name_title flex-between">
          <div class="fs20">选择证书抬头</div>
          <img src="../assets/static/icon/icon_close.png" class="icon_close" @click="addCertPopup()" alt="" />
        </div>
        <div class="cirticle_info">
          <div class="must fs16 col9 info_title">中文公司名称：</div>
          <input type="text" placeholder="请输入名称" class="input1" v-model="add_edit.companyName" />
          <div class="must fs16 col9 info_title">中文公司详细地址：</div>
          <input type="text" class="input2" placeholder="请输入详细地址" v-model="add_edit.address" />
          <div class="must fs16 col9 info_title">英文公司名称：</div>
          <input type="text" class="input1" placeholder="请输入国家" v-model="add_edit.companyNameEn" />
          <div class="must fs16 col9 info_title">英文公司详细地址：</div>
          <input type="text" class="input1" placeholder="请输入国家" v-model="add_edit.addressEn" />
          <div class="must fs16 col9 info_title">国家(须用英文填写)</div>
          <input type="text" class="input1" placeholder="请输入国家" v-model="add_edit.country" />
          <div class="flex-box">
            <div class="fs16 col9 mr30">设为默认证书抬头</div>
            <div class="switch" :class="add_edit.defaultFlag == 1 ? 'on' : ''" @click="setDefault()"></div>
          </div>
          <div class="save" @click="save_data()">保存</div>
        </div>
      </div>
    </Popup>


    <Popup :visible="popup" :confirmText="false" :cancleText="false" @close="closePopup">
      <div class="sure_pop">
        <img src="../assets/static/icon/icon_close.png" class="icon_close" @click="closePopup()" alt="" />
        <img src="../assets/static/icon/icon_contract.png" class="icon_contract" alt="" />
        <div class="fs16 tc pb40">是否确定生成合同？</div>
        <div class="sure_cancel flex-box flex-center fs18">
          <div @click="closePopup">取消</div>
          <div @click="create">确定</div>
        </div>
      </div>
    </Popup>

    <Footer></Footer>
  </div>
</template>
<script>
import axios from "axios";
import VueDatepickerLocal from "vue-datepicker-local";
import Pagination from "@/components/pagination.vue";
import { Loading, Message, MessageBox } from "element-ui"; // 消息提示
import cookie from "vue-cookies";
export default {
  components: {
    VueDatepickerLocal,
    Pagination,
  },
  data() {
    return {
      orderPop: false,
      oss: this.global.uploadOss,
      show: false,
      show2: false,
      agreement: false,
      agreement2: false,
      agreement3: false,
      detail: {},
      bankCard: {},
      local: {
        // 定制化日期选择的格式内容
        dow: 1, // Monday is the first day of the week
        yearSuffix: "年", // format of head
        monthsHead: "1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月".split("_"), // months of head
        months: "一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月".split(
          "_"
        ), // months of panel
        weeks: "一_二_三_四_五_六_日".split("_"), // weeks
        cancelTip: "cancel",
        submitTip: "confirm",
      },
      infoPop: false,
      addCertAct: false,
      post_data: {
        beginPurchaseDate: "", // 默认选择当前日期
        endPurchaseDate: "", // 默认选择当前日期
        certificateId: 0, //证书id,
        useage: "", //用途
        referee: "", //推荐人
        remark: "", //备注
		// mao add
		mail:"",//邮箱
      },
      certificate: [],
      pageSize: 5, //分页大小
      total: 0, //分页总数
      pageNum: 1, // 分页记录数
      choose_cert: {}, // 选择证书
      add_edit: {
        defaultFlag: "0",
      }, //编辑添加
      id: 0,
      is_show: 0, //0:不显示修改信息，1:修改提交审核
      content: {},
      title: '',
      is_cancel_type: 0, //1:取消订单,2:删除订单,3:已付款,付款作废
      images: [],
      voucher: false,
      createTime_text: {
        day: '',
        time: ''
      },
      completeDate_text: {
        day: '',
        time: ''
      },
      purchaseDate_text: {
        day: '',
        time: ''
      },
      invoices_list: [],
      popup: false
    };

  },


  watch: {
    date(to, from) {
      // console.log("to:", to);
      if (to === "") {
        this.checkFields("date");
      } else {
        this.checkFocus("date");
      }
    },
  },

  // 页面渲染完执行
  created() {
    let that = this;
    var id = unescape(that.$route.query.code)
    that.id = id.replace('环保桥', '')
    that.order_detail(that.id);
    that.invoices()
    that.get_certificate();

  },


  methods: {
    //申请跳转发票和合同
    jump_type(type) {
      let data = this.detail
      if (type == 1) {
        //发票
        let in_list = this.invoices_list
        if (in_list.length <= 0) {
          this.$message.error("请先完善发票信息");
          setTimeout(() => {
            this.$router.push("/invoices_maintain");
          }, 800);
        } else {
          var id = escape('环保桥' + data.id)
          var tradeNo = escape('环保桥' + data.tradeNo)
          this.$router.push("/invoices_apply?id=" + id + '&no=' + tradeNo);
        }
      } else if (type == 2) {
        // 合同
        this.popup = !this.popup
      }
    },

    create() {
         //判断用户是否完善了公司信息
         let userinfo = cookie.get("huanbaoqiao_userinfo");
      if(!userinfo.companyName || !userinfo.companyNameEn ||!userinfo.businessLicense 
         ||!userinfo.companyAddress ||!userinfo.contactor || !userinfo.contactorTel ||!userinfo.contactorEmail ){
        this.$message.error('请先完善公司信息');
        setTimeout(function(){
          that.$router.push("/info")
        },800)
        return;
      }
      this.$api.createDraftContract({ tradeId: this.id }).then((res) => {
        if (res.resCode == 0) {
          this.$message.success(res.resMsg);
          this.popup = false;
          this.order_detail(this.id)
        } else {
          this.$message.error(res.resMsg);
        }
      });
    },

    closePopup() {
      this.popup = !this.popup
    },


    //跳转协议
    jump_agree(type) {
      this.$router.push("/agreement?type=" + type);
    },

    show_cancel(type) {
      if (type == 1) {
        this.title = '是否取消该订单？'
      } else if (type == 2) {
        this.title = '是否删除该订单？'
      }
      this.orderPop = !this.orderPop
      this.is_cancel_type = type
    },
    closePop() {
      this.orderPop = !this.orderPop;
    },

    // 取消，删除操作	
    save_order() {
      let that = this
      let type = that.is_cancel_type
      if (type == 1) {
        that.$api.apiCancelOrder({ tradeId: that.id})
          .then((res) => {
            if (res.resCode == 0) {
              that.$message.success("取消成功");
              setTimeout(function () {
                that.$router.push("/my_order");
              }, 800);
            } else {
              that.$message.error(res.resMsg);
            }
          });
      } else {
        let userinfo = cookie.get("huanbaoqiao_userinfo");
        that.$api.apiOrderDelete({ id: that.id,updaterId:userinfo.id })
          .then((res) => {
            if (res.resCode == 0) {
              that.$message.success("删除成功");
              setTimeout(function () {
                that.$router.push("/my_order");
              }, 800);
            } else {
              that.$message.error(res.resMsg);
            }
          });
      }
    },


    //  修改订单模块
    // 选择其他的 抬头
    choose_certificate(data) {
      this.choose_cert = data;
      this.post_data.certificateId = data.id;
    },
    //审核不通过提交订单
    submit() {
      let that = this;
      let s_data = that.post_data;
      if (s_data.certificateId <= 0) {
        that.$message.error("请选择抬头证书");
        return;
      }
      if (s_data.beginPurchaseDate == "") {
        that.$message.error("请选择绿证消费开始时间");
        return;
      } else {
        let beg_date = s_data.beginPurchaseDate;
        if (typeof beg_date == "object") {
          let s_Date = this.time(beg_date);
          s_data.beginPurchaseDate = s_Date;
        }
      }
      if (s_data.endPurchaseDate == "") {
        that.$message.error("请选择绿证消费结束时间");
        return;
      } else {
        let end_date = s_data.endPurchaseDate;
        if (typeof end_date == "object") {
          let s_end_Date = this.time(end_date);
          s_data.endPurchaseDate = s_end_Date;
        }
      }
      if (s_data.useage == "") {
        that.$message.error("请填写用途");
        return;
      } else {
        if (/.*[\u4e00-\u9fa5]+.*$/.test(s_data.useage)) {
          that.$message.error("用途不能含有汉字！");
          return false;
        }
      }
      let s_agreement = that.agreement;
      if (s_agreement == false) {
        that.$message.error("请先同意服务协议");
        return;
      }
      let s_agreement2 = that.agreement2;
      if (s_agreement2 == false) {
        that.$message.error("请先同意隐私政策");
        return;
      }
      let s_agreement3 = that.agreement3;
      if (s_agreement3 == false) {
        that.$message.error("请先同意免责声明");
        return;
      }
      s_data.status = 'CHECK'
      s_data.id = that.id
      that.$api.apiOrderupdate(s_data).then((res) => {
        if (res.resCode == 0) {
          // console.log(res)
          that.$message.success("提交成功");
          setTimeout(function () {
            that.$router.push("/my_order");
          }, 800)

        } else {
          that.$message.error(res.resMsg);
        }
      });
    },
    // 添加|编辑提交
    save_data() {
      let _that = this;
      let add_edit = _that.add_edit;
      if (add_edit.defaultFlag) {
        add_edit.defaultFlag = "1";
      } else {
        add_edit.defaultFlag = "0";
      }
      _that.$api.saveCertificate(add_edit).then((res) => {
        if (res.resCode == 0) {
          _that.$message.success("保存成功");
          _that.addCertAct = false;
          _that.get_certificate();
        } else {
          _that.$message.error("保存失败");
        }
      });
    },
    // 默认抬头
    setDefault() {
      let _that = this;
      _that.add_edit.defaultFlag = !_that.add_edit.defaultFlag;
    },
    // 添加证书
    addCertPopup() {
      this.addCertAct = !this.addCertAct;
    },
    // 显示选择其他证书
    closePop2() {
      this.infoPop = !this.infoPop;
    },
    // 分页
    pageTurn(page) {
      this.pageNum = page + 1;
      this.get_certificate();
    },
    //证书抬头
    get_certificate() {
      var that = this;
      let page = that.pageNum;
      let s_param = {
        deviceType: "1",
        sortProp: "defaultFlag",
        sortType: "DESC",
        page: page,
        limit: that.pageSize,
        deleteFlag:"0",
      };
      that.$api.getCertificate(s_param).then((res) => {
        if (res.resCode == 0) {
          that.certificate = res.root;
          that.total = res.totalCount;
        } else {
          that.$message.error(res.resMsg);
        }
      });
    },

    time(date) {
      let Y = date.getFullYear(),
        M = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1,
        D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return Y + "-" + M + "-" + D;
    },

    disabledDate(date) {
      if (date.getTime() < this.beginPurchaseDate) {
        // 当前日期以前的时间禁用
        return true;
      }
    },
    agree() {
      this.agreement = !this.agreement;
    },

    agree2() {
      this.agreement2 = !this.agreement2;
    },
    agree3() {
      this.agreement3 = !this.agreement3;
    },

    change_is_show() {
      if (this.is_show == 0) {
        this.is_show = 1
      } else {
        this.is_show = 0
      }
    },

    //上传支付凭证
    upload_img(e) {
      let that = this
      let formdata = new FormData();
      Array.from(e.target.files).map((item) => {
        formdata.append("file", item); //将每一个文件图片都加进formdata
      });
      let loading = that.$loading({
        lock: true,
        text: '上传中，请稍候...',
        spinner: 'el-icon-loading',
        // background: 'rgba(0, 0, 0, 0.5)'
      })
      axios.post(axios.defaults.baseURL + "v1/cdn/ali/upload", formdata).then((res) => {
        loading.close();
        if (res.data.resCode == 0) {
          let images = that.images;
          let data = res.data.root;
          images.push(data);
          that.images = images;
        } else {
          that.$message.error(res.data.resMsg);
        }
      });
    },

    //删除图片
    del_img(index) {
      let list = this.images
      list.splice(index, 1)
      this.images = list
    },
    //支付凭证提交
    payMoney() {
      let that = this;
      let images = that.images;
      if (images.length <= 0) {
        that.$message.error("请上传支付凭证");
        return;
      }
      that.$api.apiUploadProof({ id: that.id, purchaseProof: images.join(",") })
        .then((res) => {
          that.voucher = false;
          if (res.resCode == 0) {
            that.$message.success("提交成功,待后台审核");
            setTimeout(function () {
              that.$router.push("/my_order");
            }, 800);
          } else {
            that.$message.error(res.resMsg);
          }
        }).catch((err) => {
          that.voucher = false;
        });
    },
    payMoneyShow() {
      this.voucher = !this.voucher;
    },

    voucherPop() {
      this.voucher = false;
    },
    //订单详情
    order_detail(id) {
      let that = this;
      let data = {
        id: id,
        deviceType: "1",
        pkg_product_father: 'com.dazz.business.model.product.Product',
        pkg_certificate_father: 'com.dazz.business.model.certificate.Certificate',
      };
      that.$api.apiOrderDetail(data).then((res) => {
        if (res.resCode == 0) {
          let data = res.root[0]
          if (data.bankCard != null && data.bankCard.length > 0) {
            that.bankCard = data.bankCard[0]
          }
          if (data.status == 'FAILED' || data.status == 'UNPAID') {
            let up_data = {
              beginPurchaseDate: data.beginPurchaseDate,
              endPurchaseDate: data.endPurchaseDate,
              useage: data.useage, //用途
              remark: data.remark,
              referee: data.referee, //推荐人
            }
            that.post_data = up_data
            that.get_content()
          }
          if (data.certificate != null && data.certificate.length > 0) {
            that.choose_cert = data.certificate[0]
            that.post_data.certificateId = data.certificate[0].id
          }
          if (data.createTime != '' && data.createTime != null) {
            var obj = {
              day: data.createTime.substr(0, 10),
              time: data.createTime.substr(11, 20)
            }
            that.createTime_text = obj
          }
          if (data.purchaseDate != '' && data.purchaseDate != null) {
            var obj = {
              day: data.purchaseDate.substr(0, 10),
              time: data.purchaseDate.substr(11, 20)
            }
            that.purchaseDate_text = obj
          }

          if (data.completeDate != '' && data.completeDate != null) {
            var obj = {
              day: data.completeDate.substr(0, 10),
              time: data.completeDate.substr(11, 20)
            }
            that.completeDate_text = obj
          }

          for (var k = 0; k < data.product.length; k++) {
            data.product[k].price_text = that.global.addCommas(data.product[k].price)
          }
          data.totalCount_text = that.global.addCommas(data.totalCount)
          data.totalAmount_text = that.global.addCommas(data.totalAmount)
          that.detail = data
        } else {
          that.$message.error(res.resMsg);
        }
      });
    },

    invoices() {
      let that = this
      that.$api.userInvoices({}).then((res) => {
        if (res.resCode == 0) {
          that.invoices_list = res.root
        } else {
          that.$message.error(res.resMsg);
        }
      }).catch((err) => {
        console.log(err);
      });
    },


    //付款说明
    get_content() {
      let that = this;
      this.$api
        .apiLanguage({ contentKeys: "LANGUAGETEST" })
        .then((res) => {
          //富文本使用
          let data = res.root[0];
          that.content = data;
        })
        .catch((err) => {
          console.log(err);
        });
    },


  },

};
</script>
